import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { GatsbyImage, getImage, getImageData } from "gatsby-plugin-image"

// eslint-disable-next-line
export const WorkPageTemplate = ({ 
  title,
  featuredimage,
  content, 
  contentComponent, 
  intro,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div className={`is-flex is-flex-direction-${ typeof window !== "undefined" && window.innerWidth < 768 ? "column" : "row"} is-align-items-flex-start`}>
                <img 
                  src={ featuredimage.childImageSharp.gatsbyImageData.images.fallback.src } 
                  style={{ 
                    maxWidth: typeof window !== "undefined" && window.innerWidth < 768 ? "100%" : "50%", 
                    marginTop: "3%"
                  }}
                />
                <div style={{ margin: "3%"}}>
                  <div className="larger-font" dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
              </div>
              <br/>
              <br/>
              <div className="columns is-multiline" >
                <div className="column is-4" style={{}}>
                  { intro != null && intro.images?  intro.images.map((object, index) => (
                    index % 3 == 0 ? <GatsbyImage image={ getImage(object.image) } alt={ object.alttext } style={{ margin: "1rem 0rem"}}/> : ""
                  )) : ""}
                </div>
                <div className="column is-4">
                  { intro != null && intro.images?  intro.images.map((object, index) => (
                    index % 3 == 1 ? <GatsbyImage image={ getImage(object.image) } alt={ object.alttext } style={{ margin: "1rem 0rem"}}/> : ""
                  )) : ""}
                </div>
                <div className="column is-4">
                  { intro != null && intro.images?  intro.images.map((object, index) => (
                    index % 3 == 2 ? <GatsbyImage image={ getImage(object.image) } alt={ object.alttext } style={{ margin: "1rem 0rem"}}/> : ""
                  )) : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

WorkPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  featuredimage: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  intro: PropTypes.shape({
    images: PropTypes.array,
  }),
};

const WorkPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <WorkPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        content={post.html}
        intro={post.frontmatter.intro}
      />
    </Layout>
  );
};

WorkPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WorkPage;

export const workPageQuery = graphql`
  query WorkPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
        hoveredimage {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
        alt
        intro {
          images {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  quality: 100
                  layout: CONSTRAINED
                )
              }
            }
            alttext
          }
        }
      }
    }
  }
`;
